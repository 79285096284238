@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'css/font.css';

@layer base {
    body {
        @apply bg-white font-serif overflow-x-hidden font-light;
    }

    hr {
        @apply border-t-default-600-hex block relative;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-sans tracking-normal;
        font-weight: 500;
    }

    a {
        @apply underline
    }

    h1 {
        @apply text-responsive-h1;

    }

    h2 {
        @apply text-responsive-h2;
    }

    h3 {
        @apply text-responsive-h3;
    }

    h4 {
        @apply text-responsive-h4;
    }

    h5 {
        @apply text-responsive-h5;
    }

    h6 {
        @apply text-responsive-h6;
        font-weight: 600;
    }

    p {
        @apply leading-7;



        &.lead {
            @apply text-xl leading-loose;
            /*@media (max-width: theme('screens.lg')) {
            @apply text-sm;
        }*/

            @media (max-width: theme('screens.lg')) {
                @apply leading-tight;
            }
        }
    }

    section {
        @apply py-40 relative;
        z-index: 1;

        @media (max-width: theme('screens.lg')) {
            @apply py-24;
        }

        @media (max-width: theme('screens.md')) {
            @apply py-24;
        }

        @media (max-width: theme('screens.sm')) {
            @apply py-24;
        }
    }

    .btn {
        @apply py-4 px-8 font-sans inline-block rounded-lg;

        &.btn-secondary {
            @apply bg-secondary-500-hex text-white;

            svg {
                path {
                    @apply fill-white;
                }
            }
        }

        &.btn-primary {
            @apply bg-primary-500-hex text-white;

            svg {
                path {
                    @apply fill-white;
                }
            }
        }

        &.btn-default {
            @apply bg-primary-100-hex text-primary-900-hex;

            svg {
                path {
                    @apply fill-primary-900-hex;
                }
            }
        }

    }

    label {
        @apply flex;

        .form-control {
            @apply h-full;
        }
    }

    .form-control {
        @apply block bg-white flex-1 border-0 py-3 px-4 rounded-lg font-sans;
    }
}

@layer components {
    .primary-bg {
        @apply bg-primary-500-hex text-primary-100;
    }

    .text-primary {
        @apply text-primary-500-hex;
    }

    .default-bg {
        @apply bg-default-500-hex;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            // @apply text-primary-900-hex;
        }
    }

    .primary-secondary {
        @apply bg-primary-900-hex text-primary-100;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @apply text-primary-100;
        }
    }

    .container {
        @apply mx-auto px-2;

        @media (max-width: theme('screens.xl')) {
            @apply max-w-full px-6;
        }

        @media (max-width: theme('screens.sm')) {
            @apply px-4;
        }

        .row {
            @apply flex flex-wrap;
        }
    }

    .banner {
        @apply py-0;

        .img-holder {
            @media (max-width: theme('screens.lg')) {
                width: 125%;
            }

            @media (max-width: theme('screens.md')) {
                width: 100%;
                max-height: 100vh;
                overflow: hidden;
            }

            img {
                width: 100%;
            }
        }

        .content {
            @apply absolute top-0 w-full py-16;

            @media (max-width: theme('screens.2xl')) {
                @apply py-4;
            }

            @media (max-width: theme('screens.md')) {
                @apply text-center;
            }

            .navbar-brand {
                @apply inline-block mb-24 w-40;

                @media (max-width: theme('screens.2xl')) {
                    @apply mb-16;
                }

                @media (max-width: theme('screens.lg')) {
                    @apply w-32;
                }

                @media (max-width: theme('screens.sm')) {
                    @apply w-24;
                }

                @media (max-width: theme('screens.md')) {
                    @apply inline-block mx-auto mb-4;
                }

                svg {
                    width: 100%
                }
            }

            h1 {
                @apply tracking-tight;

                @media (max-width: theme('screens.md')) {
                    @apply text-5xl;
                }

                @media (max-width: theme('screens.sm')) {
                    @apply text-4xl;
                }
            }


            ul {
                @apply flex my-12 space-x-4;

                @media (max-width: theme('screens.2xl')) {
                    @apply my-6;
                }

                @media (max-width: theme('screens.xl')) {
                    @apply space-x-2;
                }

                @media (max-width: theme('screens.lg')) {
                    @apply hidden;
                }

                li {
                    @apply font-sans flex justify-center;



                    span {
                        @apply w-6 h-6 rounded-full inline-block me-4 bg-primary-100-hex font-sans-medium;
                    }
                }
            }

            form {
                @media (max-width: theme('screens.lg')) {
                    @apply pt-8;
                }

                label {
                    @apply space-x-4;


                    .form-control {
                        @media (max-width: theme('screens.md')) {
                            @apply py-3 px-2;
                        }
                    }
                }

                .btn {
                    @media (max-width: theme('screens.md')) {
                        @apply py-3 px-3;
                    }
                }
            }
        }

    }

    .currency {
        @apply absolute text-4xl px-4 py-1 rounded-3xl -rotate-12;
        background: #DFF600;
        border-bottom-left-radius: 0;
        top: -12px;
        right: -72px;

        @media (max-width: theme('screens.lg')) {
            @apply text-xl;
            right: -56px;
            top: -24px
        }

        &.sm {
            @apply text-2xl;
            right: -64px;

            @media (max-width: theme('screens.lg')) {
                @apply text-lg;
                right: -24px;
                top: -24px
            }
        }
    }

    .testimonials {
        p {
            line-height: 1.4
        }
    }

    .get-paid-sec {
        @apply relative overflow-hidden;

        svg {
            @apply w-9;
        }

        .get-paid {
            .bg-logo {
                @apply absolute -top-64 w-4/12 z-0;

                svg {
                    @apply w-full;
                }
            }

            h2 {
                @apply relative z-10
            }

        }

        .service-info {
            @apply bg-secondary-50-hex p-8 rounded-lg;
            background: linear-gradient(90.57deg, #D5D1FF 0.37%, #FFDCFF 132.26%);

            svg {
                @apply w-auto h-24
            }
        }
    }

    .features {
        .fetaures-itee-cont {
            flex: 1 1 auto;
        }

        .feature-item {
            @apply bg-white rounded-xl overflow-hidden h-full border-default-600-hex border;

            h3 {
                @media (max-width: theme('screens.lg')) {
                    @apply text-4xl;

                    br {
                        display: none
                    }
                }
            }

        }
    }

    .faq {
        .collapse-cont {
            .collapse-item {
                @apply border-t-default-600-hex border-t;

                .lead {
                    @apply py-6;

                    .btn {
                        @apply p-0 font-bold text-left;
                    }
                }
            }
        }
    }

    .bottom-getstarted {
        .content {
            @apply bg-primary-500-hex p-16 rounded-lg overflow-hidden;

            @media (max-width: theme('screens.lg')) {
                @apply px-8 pt-12 pb-8
            }
        }

        .img-cont {
            @apply bg-secondary-50-hex h-full rounded-lg;
        }

        form {
            label {
                @apply space-x-4;


                .form-control {
                    @media (max-width: theme('screens.md')) {
                        @apply py-3 px-2;
                    }
                }
            }

            .btn {
                @media (max-width: theme('screens.md')) {
                    @apply py-3 px-3;
                }
            }
        }
    }

    footer {

        .divider {}

        svg {
            @apply w-full;

            path {}
        }
    }


    .carousel-container {
        position: relative;
        max-width: 100%;
        margin: auto;
        overflow: hidden;


        .carousel-slide img {
            width: 100%;
            height: auto;
            display: block;
        }

        /* Hide all slides by default */
        .carousel-slide {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        /* Add animation for fading effect */
        .fade {
            animation: fadeEffect 1s;
            position: relative;
        }

        @keyframes fadeEffect {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        /* Navigation controls */
        .prev,
        .next {
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: auto;
            padding: 16px;
            margin-top: -22px;
            font-weight: bold;
            font-size: 18px;
            transition: 0.6s ease;
            border-radius: 0 3px 3px 0;
            user-select: none;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .next {
            right: 0;
            border-radius: 3px 0 0 3px;
        }

        .prev:hover,
        .next:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }




.accordion {
    width: 100%;
    margin: auto;

    .accordion-item {
        margin-bottom: 10px;
        @apply border-t-default-600-hex border-t;
      }
      
      .accordion-header {
        padding: 15px;
        cursor: pointer;
        user-select: none;

        .lead{
            @apply font-sans-medium;
        }
      }
      
      
      
      .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
      }
      
      .accordion-content p {
        padding: 15px;
        margin: 0;
      }
  }

}

  
 